@import '../reset.css';
@import '../custom-animate.css';
@import '../../fonts/general-sans/general-sans.css';
@import '../top-navigation.css';
@import '../navigation-dropdown.css';
@import '../navigation-drawer.css';
/* @import "../testimonial-slider.css"; */
@import '../footer.css';
@import '../cookies.css';

@import '../globals.css';
@import '../typography.css';

/* #region --- Section Not Found --- */
.section--notFound {
  max-width: 1440px;
  margin: 0 auto;
  padding: 60px 24px;
}
.section--notFound-title {
  color: var(--neutral-100);
  text-align: center;
  margin-bottom: 24px;
  font-family: 'General Sans', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}
.section--notFound-desc {
  color: var(--neutral-90);
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
/* #endregion */

/* #region --- Section CTA Home --- */
.section-cta__home {
  position: relative;
  background-image: url('../../image/cta-background.webp');
  background-size: 250% 250%;
  background-position: bottom;
  margin-top: -1px;
}
.rectangle-bottom__section-cta__home {
  display: flex;
  width: 100%;
  position: absolute;
  bottom: -1px;
  left: 0;
}
.rectangle-bottom__section-cta__home svg {
  min-height: 52px;
}
.section-cta__inner__home {
  padding: 80px 24px 104px;
  max-width: 1440px;
  margin: auto;
}

.section-cta__content__home {
}
.section-cta__title__home {
  color: var(--neutral-10);
  text-align: center;
  margin-bottom: 24px;

  /* Landing Page/Heading/H4 */
  font-family: 'General Sans', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 48px */
}
.section-cta__description__home {
  color: var(--neutral-10);
  text-align: center;
  max-width: 486px;
  margin: 0 auto 40px;

  /* Body Medium/Medium */
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
}

.btn-book-demo__home {
  margin: auto;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  padding: 0 16px;
  background: var(--neutral-10);
  border-radius: 54px;
}

@media (min-width: 992px) {
  .section-cta__home {
    background-size: 100% 100%;
    background-position: right;
  }
  .section-cta__inner__home {
    padding: 80px 120px;
  }
}

@media (min-width: 1200px) {
  .section-cta__inner__home {
    padding: 120px;
  }
}
/* #endregion */
